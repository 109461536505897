import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { dropIn } from './Animations'
import close from '@images/close.svg'
import { useStateContext } from '../../context/stateContext'
import btn from '@images/btn_w.png'
import btnEN from '@images/btn_w_EN.png'
import btnMob from '@images/btn_w_mob.png'
import btnMobEN from '@images/btn_w_mob_EN.png'
import {
  hopfen,
  lemon,
  pack,
  hopfen_shadow,
  pack_shadow,
} from '@images/sticky-button'
import btnPeel from '@images/btn_w_peel.png'
import btnPeelEN from '@images/btn_w_peel_EN.png'
import btnPeelMob from '@images/btn_w_peel_mob.png'
import btnPeelMobEN from '@images/btn_w_peel_mob_EN.png'
import { useBreakpoint } from '@hooks/useBreakpoint'
import img01 from '@images/kathi.png'
import {I18nextContext} from "gatsby-plugin-react-i18next";

const StyledStickyBottom = tw(motion.div)`
  h-[60px] md:h-[110px] fixed bottom-0 w-full bg-lightGray z-50 flex justify-between pl-4 xxs:p-0 md:justify-center items-center lg:pl-0 xs:px-1.5 max-w-screen-2k mx-auto
`

const Button = styled(motion.button)`
  ${tw`font-lobster rounded-tr-[50px] relative w-[113px] h-[50px] md:w-[299px] md:h-[82px] mr-10 md:mr-20`}
`
const CloseButton = styled.button`
  ${tw`absolute w-[22px] h-[22px] sm:w-7 sm:h-7 top-0 -translate-y-1/2 right-3 lg:w-9 lg:h-9 rounded-full bg-darkGray flex items-center justify-center lg:right-0 macbook:-right-5 z-10 border-2 border-white lg:ml-9 ml-0.5`}
`

const ImageWrap = styled.div`
  ${tw`w-1/3 self-end max-w-[220px] hidden xxs:block relative`}
`
const Image = styled.img`
  ${tw`w-full h-full`}
`

const Text = styled.p`
  ${tw`font-lobster text-radler text-18px p-1 leading-[22px] lg:text-2xl xl:text-3xl lg:leading-[57px] lg:px-8 w-1/2 xxs:w-[42%] xxs:text-center md:ml-0 mr-auto xxs:mr-0`}
`

const Content = tw.div`
  max-w-5xl mx-auto flex max-h-full justify-center items-center w-full relative
`

const Glow = styled.div`
  ${tw` absolute top-0 left-0 h-full bg-white w-[100%] blur-[74px] opacity-[0.45] z-[-1]`}
  transform: translateZ(0);
`

const AnimationWrapper = styled(motion.div)`
  ${tw`absolute 3xl:ml-10 h-[182px] w-[160px] flex-shrink-0 scale-[0.3] xxs:scale-40 md:scale-100 origin-top lg:origin-right
  left-auto z-[-1] md:-top-24 md:-right-18 `};
`
const MotionImage = styled(motion.img)`
  ${tw`absolute bottom-0 left-0 object-contain`};
`

const StickyBottom = ({ content, section }) => {
  const { stickyHandle, modalHandle } = useStateContext()
  const { language } = React.useContext(I18nextContext)
  const isEnglish = language === 'en'
  const [hovering, setHovering] = React.useState(false)
  const { isMobile, isTablet } = useBreakpoint()
  const gotoHandelspartner = () => window.location = '/handelspartner'
  const goToFlaschenpost = () => window.open('https://www.flaschenpost.de/erdinger-brauhaus', '_blank')

  const [animationState, setAnimationState] = useState(false)

  const btnVariants = {
    init: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0,
      },
    },
    prodInit: {
      opacity: 0,
      y: 10,
      transition: {
        duration: 0.2,
      },
    },
    prodAnimate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        type: 'spring',
      },
    },
  }

  if (!section) return null
  if (!!section)
    return (
      <>
        <StyledStickyBottom
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Content>
            <ImageWrap>
              <Image src={img01} alt="Kathi" />
              <Glow />
            </ImageWrap>
            <Text>{content.text[section - 1]}</Text>
            <Button
              onHoverStart={() => {
                setHovering(true)
                setAnimationState(true)
              }}
              onHoverEnd={() => setHovering(false)}
              onClick={isEnglish ? goToFlaschenpost : gotoHandelspartner}
            >
              <AnimatePresence exitBeforeEnter={false} initial={true}>
                <motion.img
                  key="btn-init"
                  variants={btnVariants}
                  initial="animate"
                  tw="absolute top-0 left-0 right-0 pointer-events-none"
                  animate={
                    hovering
                      ? 'init'
                      : !!animationState
                      ? {
                          opacity: [0, 0, 0, 1],
                          transition: {
                            duration: 0.05,
                            ease: 'easeInOut',
                            delay: 0.3,
                          },
                        }
                      : 'animate'
                  }
                  src={isMobile ? (isEnglish ? btnMobEN : btnMob) : (isEnglish ? btnEN : btn)}
                  alt="Jetzt kaufen"
                />
                {!isTablet && (
                  <AnimationWrapper>
                    <MotionImage
                      tw="w-16 left-[unset] right-0 bottom-[17%]"
                      initial="prodInit"
                      variants={btnVariants}
                      animate={
                        hovering
                          ? {
                              scale: 1,
                              y: 0,
                              opacity: 1,
                              transition: {
                                type: 'spring',
                                duration: 0.4,
                                bounce: 0.4,
                                delay: 0.4,
                              },
                            }
                          : {
                              scale: 0.3,
                              opacity: 0,
                              y: 10,
                              transition: {
                                duration: 0.2,
                              },
                            }
                      }
                      src={hopfen_shadow}
                      alt="Schatten"
                    />
                    <MotionImage
                      tw="w-[140px] left-[5%] bottom-[0]"
                      initial="prodInit"
                      variants={btnVariants}
                      animate={hovering ? 'prodAnimate' : 'prodInit'}
                      src={pack_shadow}
                      alt="Schatten"
                    />
                    <MotionImage
                      tw="w-10 left-[unset] right-0 bottom-1/4"
                      initial="prodInit"
                      variants={btnVariants}
                      animate={
                        hovering
                          ? {
                              scale: 1,
                              y: 0,
                              opacity: 1,
                              transition: {
                                type: 'spring',
                                duration: 0.4,
                                bounce: 0.4,
                                delay: 0.4,
                              },
                            }
                          : {
                              scale: 0.3,
                              opacity: 0,
                              y: 10,
                              transition: {
                                duration: 0.2,
                              },
                            }
                      }
                      src={hopfen}
                      alt="Hopfen"
                    />
                    <MotionImage
                      tw="w-[92px] left-[unset] right-[19%] top-[18.2%]"
                      initial="prodInit"
                      variants={btnVariants}
                      animate={hovering ? 'prodAnimate' : 'prodInit'}
                      src={pack}
                      alt="ERDINGER Brauhaus Karton"
                    />

                    <MotionImage
                      tw="w-18 top-0"
                      initial="prodInit"
                      variants={btnVariants}
                      animate={
                        hovering
                          ? {
                              scale: 1,
                              y: 0,
                              opacity: 1,
                              transition: {
                                type: 'spring',
                                duration: 0.3,
                                bounce: 0.6,
                                delay: 0.3,
                              },
                            }
                          : {
                              scale: 0.3,
                              opacity: 0,
                              y: 10,
                              transition: {
                                duration: 0.2,
                              },
                            }
                      }
                      src={lemon}
                      alt="Zitrone"
                    />
                  </AnimationWrapper>
                )}
                <motion.img
                  key="btn-peel"
                  variants={btnVariants}
                  initial="init"
                  tw="absolute top-0 left-0 right-0 pointer-events-none"
                  animate={
                    hovering
                      ? 'animate'
                      : !!animationState
                      ? {
                          opacity: [1, 1, 1, 0],
                          transition: {
                            duration: 0.05,
                            ease: 'easeInOut',
                            delay: 0.3,
                          },
                        }
                      : 'init'
                  }
                  src={isMobile ? (isEnglish ? btnPeelMobEN : btnPeelMob) : (isEnglish ? btnPeelEN : btnPeel)}
                  alt="Jetzt kaufen"
                />
              </AnimatePresence>
            </Button>
            <CloseButton onClick={() => stickyHandle(false)}>
              <img
                src={close}
                alt="Schliessen"
                tw="w-2 h-2 sm:h-3 sm:w-3 lg:h-auto lg:w-auto"
              />
            </CloseButton>
          </Content>
        </StyledStickyBottom>
      </>
    )
}

export default StickyBottom
